import type { Asset } from '@blissbook/lib/types'

function getAssetKey(url: string) {
  return url.toLowerCase()
}

export function resolveAssetUrl(url?: string) {
  if (!url) return
  const key = getAssetKey(url)
  return sessionStorage.getItem(key) || url
}

export function storeAssetUrl(asset: Asset) {
  const key = getAssetKey(asset.path)
  sessionStorage.setItem(key, asset.url)
}

export function storeAssetUrls(assets: Asset[]) {
  for (const asset of assets) {
    storeAssetUrl(asset)
  }
}
